<template>
  <div class="date-range-select row">
    <div class="col">
      <q-select
        v-model="value"
        :options="options"
        :label="label"
        color="primary"
        :dense="dense"
        :hint="custom"
        map-options
        emit-value
        @input="createRange"
        @clear="clear"
      >
        <template v-slot:append>
          <q-icon
            v-if="value"
            class="cursor-pointer"
            name="clear"
            @click.stop="clear()"
          />
        </template>
      </q-select>
    </div>
    <q-dialog
      v-model="dialog"
      class="date-range-dialog"
      @ok="emitValue"
      @cancel="cancel"
    >
      <q-card style="width: 700px; max-width: 80vw;" class="center">
        <q-card-section>
          <div class="text-h6">
            {{ $t('select_date_range') }}
          </div>
        </q-card-section>

        <q-card-section class="center">
          <div class="row center">
            <div class="col center">
              <q-date
                v-model="startPicker"
                color="primary"
                today-btn
              />
            </div>
            <div class="col center">
              <q-date
                v-model="endPicker"
                color="primary"
                today-btn
              />
            </div>
          </div>
        </q-card-section>

        <q-card-actions>
          <q-btn v-close-popup flat :label="$t('cancel')" />
          <q-btn v-close-popup color="primary" :label="$t('confirm.dates')" @click.stop="emitValue" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import date from 'utils/date-time'
export default {
  props: {
    label: {
      type: String,
      default: 'Date range'
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: null,
      dialog: false,
      start: null,
      end: null,
      startPicker: null,
      endPicker: null,
      custom: null,
      options: [
        {
          label: this.$t('today'),
          value: 'today'
        },
        {
          label: this.$t('week_to_date'),
          value: 'week-to-date'
        },
        {
          label: this.$t('num.past_days', { num: '7' }),
          value: 'past-7-days'
        },
        {
          label: this.$t('num.past_days', { num: '30' }),
          value: 'past-30-days'
        },
        {
          label: this.$t('num.past_days', { num: '90' }),
          value: 'past-90-days'
        },
        {
          label: this.$t('custom_range'),
          value: 'custom'
        }
      ]
    }
  },
  watch: {
    start (val) {
      if (val) { this.startPicker = val.replace(/-/g, '/') }
    },
    startPicker (val) {
      if (val) { this.start = val.replace(/\//g, '-') }
    },
    end (val) {
      if (val) { this.endPicker = val.replace(/-/g, '/') }
    },
    endPicker (val) {
      if (val) { this.end = val.replace(/\//g, '-') }
    }
  },
  methods: {
    createRange (val) {
      if (val === 'custom') {
        this.dialog = true
      } else {
        this.end = date.toCivilDate(date.newDate())
        if (val === 'today') {
          this.start = date.toCivilDate()
        } else if (val === 'week-to-date') {
          this.start = date.toCivilDate(DateTime.local().set({ weekday: 1 }))
        } else if (val === 'past-7-days') {
          this.start = date.toCivilDate(DateTime.local().minus({ days: 7 }))
        } else if (val === 'past-30-days') {
          this.start = date.toCivilDate(DateTime.local().minus({ days: 30 }))
        } else if (val === 'past-90-days') {
          this.start = date.toCivilDate(DateTime.local().minus({ days: 90 }))
        }

        this.emitValue()
      }
    },
    showDialog () {
      this.dialog = true
    },
    emitValue () {
      if (this.value === 'custom') this.custom = `${date.toShortDate(this.start)} - ${date.toShortDate(this.end)}`
      this.$emit('change', {
        start: this.start,
        end: this.end
      })
    },
    cancel () {
      this.start = null
      this.end = null
      this.value = null
      this.custom = null
      this.emitValue()
    },
    clear () {
      this.cancel()
    }
  }
}
</script>

<style lang="stylus" scoped>
.date-picker
  width 300px
  display inline-block

.date-picker:nth-child(2)
  margin-left 15px

.date-range-dialog .modal-content
  width 663px

.custom-string
  display: table-cell;
  vertical-align: middle;
  height: 100%
  line-height: 1
  padding-left: 10px
  height 56px
</style>
